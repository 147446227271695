/**
 * External Dependencies
 */
import React from "react"
import { graphql } from "gatsby"

/**
 * Internal Dependencies
 */
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import parseContent from "../utils/parse-content"

// eslint-disable-next-line arrow-body-style
const pageTemplate = ({ data: { post }, pageContext: { localeSettings } }) => {
  return (
    <Layout settings={localeSettings} post={post}>
      <SEO title={post.title} description={post.excerpt} seo={post.seo} />
      <article
        className="blog-post static-page"
        itemScope
        itemType="http://schema.org/Article"
      >
        {!!post.content && (
          <section itemProp="articleBody">{parseContent(post.content)}</section>
        )}
      </article>
    </Layout>
  )
}

export default pageTemplate

export const pageQuery = graphql`
  query PageBySlug(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $slug: String!
    $locale: String!
  ) {
    # selecting the current post by slug
    post: wpPage(slug: { eq: $slug }, language: { locale: { eq: $locale } }) {
      id
      uri
      slug
      guid
      title
      content
      seo {
        metaDesc
        metaKeywords
        opengraphDescription
        opengraphImage {
          uri
          localFile {
            publicURL
          }
        }
        opengraphSiteName
        opengraphTitle
        opengraphType
        title
        twitterDescription
        twitterImage {
          uri
          localFile {
            publicURL
          }
        }
        twitterTitle
      }
    }
  }
`
